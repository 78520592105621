var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titleBar',{attrs:{"pitable":true,"inline":true,"title":" ","back":false,"showBackArrowDesktop":true,"subtitle":"","centered":false,"theme":"white","showSignup":false,"containerClass":"donotuse","showDesktop":true,"showAfterScroll":0,"scrollTopOnClick":true,"actionButtonStyle":"small","actionButtonRoundBg":"transparent","iconPack":"far","isHome":true,"actions":[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
        {
          icon: 'bell',
          to: '/notifications',
          hidden: false,
          hiddenTablet: false,
        },
        /*  {
          icon: 'comment',
          to: '/messages',
          hidden: false,
          hiddenTablet: false,
        },*/
      ]}}),_vm._v(" "),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('br'),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",attrs:{"id":"feed"}},[_c('div',{staticClass:"feedWrap",staticStyle:{"max-width":"550px","margin":"auto","display":"block"}},[_c('h3',{staticClass:"title is-3"},[_vm._v("\n                Latest posts\n                "),_c('DotDrop',{attrs:{"items":[
                    { t: 'Popular', icon: 'far fa-fire', to: `soon` },
                    // { t: 'Refresh', icon: 'far fa-sync', click: alert },
                    { t: 'Following', icon: 'far fa-user-plus', to: `soon` },
                  ]}})],1),_vm._v(" "),(_vm.$store.main.state?.profile?.hasPages)?_c('NewPostTeaser'):_vm._e(),_vm._v(" "),_c('postList',{attrs:{"canClose":false,"items":_vm.posts,"pageSize":5000,"infinite":true}})],1)]),_vm._v(" "),_c('div',{staticClass:"column is-narrow is-hidden-mobile",staticStyle:{"width":"300px"}},[_c('div',{staticClass:"rightFeedCol"}),_vm._v(" "),_vm._l((_vm.promoBoxes),function(promo){return _c('article',{key:promo.text,staticClass:"messageNO box is-warning"},[_c('div',{staticClass:"message-bodyNO"},[_c('h1',{staticClass:"title is-5"},[_vm._v(_vm._s(promo.title))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(promo.text))]),_vm._v(" "),_c('router-link',{attrs:{"to":promo.link}},[_c('b-button',{attrs:{"type":"is-info is-small  ","rounded":""}},[_vm._v(_vm._s(promo.buttonText))])],1)],1)])}),_vm._v(" "),_c('div',{staticStyle:{"padding-top":"20px"},attrs:{"id":"sticky"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.performSearch.apply(null, arguments)}}},[_c('b-field',[_c('b-input',{staticClass:"search-input",attrs:{"icon":"fas fa-search","expanded":"","rounded":"","type":"search","size":"is-medium","placeholder":"Search OnlyBots"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.performSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('h4',{staticClass:"title is-5",staticStyle:{"padding-top":"20px"}},[_vm._v("\n                Popular bots\n              ")]),_vm._v(" "),_c('bot-list',{attrs:{"bots":_vm.botsShown,"page-size":30,"pginate":true,"layout":"minilist"}})],1)],2)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.posts.length)?_c('div',{staticClass:""}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }