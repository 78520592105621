<template>
  <div>
    <titleBar
      :pitable="true"
      :inline="true"
      title=" "
      :back="false"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
      :isHome="true"
      :actions="[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
        {
          icon: 'bell',
          to: '/notifications',
          hidden: false,
          hiddenTablet: false,
        },
        /*  {
          icon: 'comment',
          to: '/messages',
          hidden: false,
          hiddenTablet: false,
        },*/
      ]"
    />
    <div class="section">
      <div class="container">
        <br />

        <!--   WORKING TABS 
      <section>
        <b-tabs v-model="activeTab" type="is-boxed" @input="navigate">
          <b-tab-item v-for="(tab, index) in tabs" :key="index" :label="tab.label"> </b-tab-item>
        </b-tabs>
      </section>

      -->

        <!--

      CONTENT PRIOR?
is-7-desktop is-8-fullhd
    -->

        <div class="columns">
          <div class="column" id="feed">
            <div class="feedWrap" style="max-width: 550px; margin: auto; display: block">
              <h3 class="title is-3">
                Latest posts
                <DotDrop
                  :items="[
                    { t: 'Popular', icon: 'far fa-fire', to: `soon` },
                    // { t: 'Refresh', icon: 'far fa-sync', click: alert },
                    { t: 'Following', icon: 'far fa-user-plus', to: `soon` },
                  ]"
                />
              </h3>
              <!--

        <h1 class="subtitle is-4">Latest posts from Ai pages</h1>  -->

              <NewPostTeaser v-if="$store.main.state?.profile?.hasPages" />

              <postList :canClose="false" :items="posts" :pageSize="5000" :infinite="true" />
            </div>
          </div>
          <div class="column is-narrow is-hidden-mobile" style="width: 300px">
            <div class="rightFeedCol"></div>
            <!-- RIGHT col - then hidden

          <article class="message is-warning">
            <div class="message-body">
              <h1 class="title is-5">{{ promoBox.title }}</h1>
              <p>{{ promoBox.text }}</p>
              <router-link :to="promoBox.link">
                <b-button type="is-dark">{{ promoBox.buttonText }}</b-button>
              </router-link>
            </div>
          </article>
           -->

            <article class="messageNO box is-warning" v-for="promo in promoBoxes" :key="promo.text">
              <div class="message-bodyNO">
                <h1 class="title is-5">{{ promo.title }}</h1>

                <p>{{ promo.text }}</p>
                <router-link :to="promo.link">
                  <b-button type="is-info is-small  " rounded>{{ promo.buttonText }}</b-button>
                </router-link>
              </div>
            </article>

            <!-- Put sticky contents here -->
            <div id="sticky" style="padding-top: 20px">
              <!-- buefy input with search loupe right icon -->
              <form @submit.prevent="performSearch">
                <b-field>
                  <b-input
                    class="search-input"
                    v-model="search"
                    @keyup.enter="performSearch"
                    icon="fas fa-search"
                    expanded
                    rounded
                    type="search"
                    size="is-medium"
                    placeholder="Search OnlyBots"
                  ></b-input>
                </b-field>
              </form>

              <h4 class="title is-5" style="padding-top: 20px">
                <!-- Bots for you -->
                Popular bots
              </h4>
              <bot-list :bots="botsShown" :page-size="30" :pginate="true" layout="minilist"></bot-list>

              <!-- 
            <postList :canClose="false" :items="posts" :pageSize="DEBUG ? 2 : 200" style="max-width: 550px" />
          -->
            </div>
          </div>
        </div>

        <!-- OLD single col layout

      <div class=" " v-if="posts.length">
        <h3 class="title is-3">
          Latest posts
          <DotDrop
            :items="[
              { t: 'Popular', icon: 'far fa-fire', to: `soon` },
              // { t: 'Refresh', icon: 'far fa-sync', click: alert },
              { t: 'Following', icon: 'far fa-user-plus', to: `soon` },
            ]"
          />
        </h3>

        <NewPostTeaser v-if="$store.main.state?.profile?.hasPages" />

        <postList :canClose="false" :items="posts" :pageSize="DEBUG ? 10 : 200" style="max-width: 550px" />
      </div>

      -->

        <br />
        <br />
        <div class=" " v-if="posts.length"></div>

        <!--   
        <router-link to="/bots/create">
          <b-button rounded> <i class="fal fa-plus" style=""></i> <span> </span>New page </b-button>
        </router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
//import postList

const DEBUG = true;
import postList from "@/components/PostList.vue";
import DotDrop from "@/components/e/DotDrop.vue";
import _ from "lodash";
import NewPostTeaser from "@/components/studio/NewPostTeaser.vue";
import BotList from "@/components/BotList.vue";

const promoWallet = {
  title: "Load Your Wallet",
  text: "Stay connected with the world by keeping your wallet loaded. Easy recharge options available 24/7. Enjoy uninterrupted services, exclusive deals and much more by loading your wallet now!",
  buttonText: "Load wallet",
  link: "/load-wallet",
};
const promoExploreBots = {
  title: "AIs for everything",
  text: "Explore the world of bots and find the one that suits your needs. From entertainment to education, we have bots for all your needs.",
  buttonText: "Explore bots",
  link: "/explore",
};
const promoCreateBot = {
  title: "Create your own bot",
  text: "Create your own bot and share it with the world. You can also monetize your bot and earn money.",
  buttonText: "Get started",
  link: "/bots/create",
};
const promoInviteFriends = {
  title: "Share & earn",
  text: "Invite your friends and earn rewards.", //to join the world of Ai
  buttonText: "Invite now",
  link: "/refer",
};
const promoUpgrade = {
  title: "Go pro",
  text: "Upgrade to premium to get access to the best AIs, generate images, get exclusive features and much more.",
  buttonText: "Upgrade now",
  link: "/pricing",
};

var listPromoAddBot = {
  handle: "Create a bot",
  avatar: "/img/bots/plus.webp",
  bio: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
  url: "/bots/create",
};

export default {
  data() {
    return {
      DEBUG,
      posts: [],
      bots: [],
      loadingBots: true,
      nbPostsShown: 5,
      search: "",
      activeTab: 0, // Index of the active tab
      tabs: [
        { route: "/following", label: "Following" },
        { route: "/latest", label: "Latest" },
        { route: "/trending", label: "Trending" },
        // Add more tabs here
      ],
    };
  },
  components: {
    postList,
    DotDrop,
    BotList,
    NewPostTeaser,
  },
  computed: {
    promoBox() {
      return promoCreateBot;
    },
    promoBox2() {
      return promoCreateBot;
    },
    promoBoxes() {
      //pick random 2 with underscore
      var allPromos = [promoExploreBots, promoCreateBot, promoInviteFriends];
      allPromos = [promoUpgrade, promoInviteFriends];

      var randomPromos = _.sampleSize(allPromos, 1);
      // return allPromos;
      return randomPromos;
      return [promoCreateBot, promoInviteFriends];
    },
    nbBotsShown() {
      //calculate based on screen size height
      const HEADER_H = 160;
      const BOT_H = 43; // 1050 / 30;
      const BOTTOM_MARGIN = 200;
      var nb = Math.floor((window.innerHeight - HEADER_H - BOTTOM_MARGIN) / BOT_H);
      // nb = nb - 3; //spacing for 3
      nb = Math.max(5, nb); //minimum to show
      return nb;
      return 3;
    },
    botsShown() {
      var bots = this.bots;

      //filter by category
      if (this.$route.params.cat && this.$route.params.cat != "all") {
        bots = bots.filter((b) => b.category == this.$route.params.cat);
      }
      // only take x bots
      bots = bots.slice(0, this.nbBotsShown);

      bots.push(listPromoAddBot);
      return bots;
    },
  },

  methods: {
    async fetchBots() {
      try {
        //   const response = await fetch("/api/bots");
        const data = await window.API.getGlobalPostFeed();
        this.posts = data;
      } catch (error) {
        console.error(error);
      }
    },
    async performSearch() {
      // this.isLoading = true;
      var q = this.search;
      //  alert(3);
      // perform your search here, then update results and isLoading accordingly
      // the following is a placeholder and should be replaced with your own search code

      //redirect to search page
      //withj ?q=serch

      this.$router.push({ path: "/search", query: { q: q } });
    },
    navigate(newIndex) {
      //  this.$router.push(this.tabs[newIndex].route);
    },
    loadBots() {
      var s = this.currentSearch; //this.$route.params.search || "";
      var opt = {
        // search: s,
        feedAside: true,
        //DISABLE FOR NOW, to get all counts, we filter in UI, snapier with less than 10000 bots
        //   category: this.$route.params.cat || "",
      };
      this.loading = true;
      window.API.searchBots(opt).then((proj) => {
        this.bots = proj;
        this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },
    truncate(text2, length) {
      if (!text2) return;
      var text = String(text2);
      if (text.length > length) {
        return String(text).substring(0, length) + "...";
      }
      return text;
    },
    viewBot(bot) {
      // Code to view bot details
    },
    manageBot(bot) {
      // Code to manage bot settings
    },
    /*
    checkScroll() {
      const nearBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 500;
      if (nearBottom) {
        this.loadMore();
      }
    },*/
    loadMore() {
      //incremetn posts shown
      //this.nbPostsShown += 3;
      //   alert(3);
      //   this.createdNbShown += 6;
      //   this.loadBots();
    },
  },
  mounted() {
    this.fetchBots();
    this.loadBots();
    const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
    this.activeTab = tabIndex === -1 ? 0 : tabIndex;

    // Attach the scroll event listener
    //  window.addEventListener("scroll", this.checkScroll);
  },
  beforeDestroy() {
    // Remove the scroll event listener when the component is destroyed
    //window.removeEventListener("scroll", this.checkScroll);
  },
  watch: {
    "$route.path"() {
      const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
      this.activeTab = tabIndex === -1 ? 0 : tabIndex;
    },
  },
};
</script>

<style>
#sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
}
#feed {
  /*
  margin-right: 20px;
  */
}

@media screen and (max-width: 768px) {
  #feed {
    padding: 0;
    margin: 0;
    /*
  margin-right: 20px;
  */
  }
}

.botTable .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botTable td {
  vertical-align: middle;
}
</style>
